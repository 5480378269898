import React, { useReact, useState } from "react"
import Banner from "../components/banner"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../App.css"

// import React, { Component } from "react"
import { graphql } from "gatsby"

// export default class menu extends Component {
//   render() {
//     return <div></div>
//   }
// }

const Menu = (props) => {
  const { data } = props
  let chineseDishes = data.allChineseDishesJson.edges
  // console.log(chineseDishes)
  let vegetarianDishes = data.allVegetarianDishesJson.edges
  let specialDishes = data.allSpecialDishesJson.edges

  const [type, setType] = useState("Chinese")

  return (
    <Layout>
      <SEO title="Menu" />
      <Banner hero="menu-banner" title="Menu">
        <div className="menu-selection">
          <a
            className={type == "Chinese" ? "selected" : ""}
            onClick={() => setType("Chinese")}
          >
            CHINESE
          </a>
          <a
            className={`has-side-border ${
              type == "Vegetarian" ? "selected" : ""
            }`}
            onClick={() => setType("Vegetarian")}
          >
            VEGETARIAN
          </a>
          <a
            className={type == "Specials" ? "selected" : ""}
            onClick={() => setType("Specials")}
          >
            SPECIALS
          </a>
        </div>
      </Banner>
      <main id="content-menu" className="container">
        {type == "Chinese" && (
          <div>
            <div className="menu-grid">
              <h1>Chinese Dishes</h1>
              {data.allChineseDishesJson.edges
                ? data.allChineseDishesJson.edges.map((dish, i) => {
                    return (
                      <div key={i} className="menu-grid-item">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{dish.node.category}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dish.node.items.map((item, i) => {
                              return (
                                <tr key={i + "item_name"}>
                                  <td>{item.item_name}</td>
                                  <td>
                                    {item.large_price
                                      ? "S - " + item.small_price
                                      : ""}
                                  </td>
                                  <td>
                                    {item.large_price
                                      ? "L - " + item.large_price
                                      : item.small_price}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    )
                  })
                : "Loading"}
            </div>
          </div>
        )}
        {type == "Vegetarian" && (
          <div>
            <div className="menu-grid">
              <h1>Vegetarian Dishes</h1>
              {data.allVegetarianDishesJson.edges
                ? data.allVegetarianDishesJson.edges.map((dish, i) => {
                    return (
                      <div key={i} className="menu-grid-item">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{dish.node.category}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dish.node.items.map((item, i) => {
                              return (
                                <tr key={i + "item_name"}>
                                  <td>{item.item_name}</td>
                                  <td>
                                    {item.large_price
                                      ? "S - " + item.small_price
                                      : ""}
                                  </td>
                                  <td>
                                    {item.large_price
                                      ? "L - " + item.large_price
                                      : item.small_price}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    )
                  })
                : "Loading"}
            </div>
          </div>
        )}
        {type == "Specials" && (
          <div>
            <div className="menu-grid">
              <h1>Sentosa Specials</h1>
              {data.allSpecialDishesJson.edges
                ? data.allSpecialDishesJson.edges.map((dish, i) => {
                    return (
                      <div key={i}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>{dish.node.category}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dish.node.items.map((item, i) => {
                              return (
                                <tr key={i + "item_name"}>
                                  <td>{item.item_name}</td>
                                  <td>
                                    {item.large_price
                                      ? "S - " + item.small_price
                                      : ""}
                                  </td>
                                  <td>
                                    {item.large_price
                                      ? "L - " + item.large_price
                                      : item.small_price}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    )
                  })
                : "Loading"}
            </div>
          </div>
        )}
      </main>
    </Layout>
  )
}

export default Menu

export const query = graphql`
  query {
    allChineseDishesJson {
      edges {
        node {
          category
          items {
            item_name
            small_price
            large_price
          }
        }
      }
    }
    allVegetarianDishesJson {
      edges {
        node {
          category
          items {
            item_name
            small_price
            large_price
          }
        }
      }
    }
    allSpecialDishesJson {
      edges {
        node {
          category
          items {
            item_name
            small_price
          }
        }
      }
    }
  }
`
